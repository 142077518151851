/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, ColumnCover, ColumnDiv, Title, Subtitle, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Home"}>
        <SiteHeader set="" currentLanguage={2} />

        <Column className="pb--50 pt--80" name={"uvod"} layout={"l30"}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s5 --center el--1 --full pt--50 flex--center" anim={"7"} animS={"5"} columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5" anim={"2"} animS={"5"}>
              
              <Image style={{"maxWidth":670}} src={"https://cdn.swbpg.com/t/24903/a3f85061ce1f49c2b3e28257de507944_s=860x_.png"} sizes="100vw" srcSet={"https://cdn.swbpg.com/t/24903/a3f85061ce1f49c2b3e28257de507944_s=350x_.png 350w, https://cdn.swbpg.com/t/24903/a3f85061ce1f49c2b3e28257de507944_s=660x_.png 660w, https://cdn.swbpg.com/t/24903/a3f85061ce1f49c2b3e28257de507944_s=860x_.png 860w, https://cdn.swbpg.com/t/24903/a3f85061ce1f49c2b3e28257de507944_s=1400x_.png 1400w, https://cdn.swbpg.com/t/24903/a3f85061ce1f49c2b3e28257de507944_s=2000x_.png 2000w"} position={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="js-anim  --anim3 --anim-s5 --center pt--20 flex--top" anim={"3"} animS={"5"}>
                  
                  <ColumnDiv >
                    
                    <Title className="title-box fs--185 w--300 lh--1" content={""}>
                    </Title>

                    <Subtitle className="subtitle-box fs--30 ls--004 lh--1 mt--0" content={"15 – 6 – 2024"}>
                    </Subtitle>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim2" style={{"paddingTop":0,"paddingBottom":0}} anim={"2"} name={"q1n4b5d9e1j"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Button className="btn-box" use={"page"} href={"/cs/podekovani"} content={"Česky"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Button className="btn-box" use={"page"} href={"/en/thank-you"} content={"English"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}